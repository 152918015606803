import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"

import "../../../styles/pages/blog-ks.scss"
import "../../../styles/pages/blog.scss"

import AllAuthorsList from "../../../components/blog/allAuthors"
import AuthorNominate from "../../../components/blog/authorNominate"
// import AuthorNominate from '../../../components/blog/authorNominate2';
import { graphql, StaticQuery } from "gatsby"
import SuggestedStories from "../../../components/blog/landingSuggestedStories"
import CTA from "../../../components/common/CTA"
import Frame from "../../../components/common/frame"
import NavigationBlog from "../../../components/navigationBlog"

let BlogAuthorsList = props => {
  // const loc = useHistory()

  const [isAuthors, setIsAuthors] = useState("")

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [showSuggestedStories, setShowSuggestedStories] = useState(false)

  const changeTab = value => {
    const url = window.location.href
    if (value === "authors") {
      setIsAuthors("Authors")
      if (
        url.includes("#WriteForUs") ||
        window.location.hash === "#WriteForUs"
      ) {
        let urlUpdate = url.split("#")[0]
        window.history.replaceState(null, "", urlUpdate)
      }
    } else {
      setIsAuthors("WriteForUs")
      if (!url.includes("#WriteForUs")) {
        let urlUpdate = url + "#WriteForUs"
        window.history.replaceState(null, "", urlUpdate)
      }
    }
  }

  useEffect(() => {
    if (
      window.location.href.includes("#WriteForUs") === "#WriteForUs" ||
      window.location.hash === "#WriteForUs"
    ) {
      setIsAuthors("WriteForUs")
    } else {
      setIsAuthors("Authors")
    }
  })

  return (
    <>
      <div className="blog">
        <StaticQuery
          query={graphql`
            query blogAuthors {
              SuperOps {
                navigationContents(where: { section: "Library" }) {
                  section
                  isParentOnly
                  parentTitle
                  parentDescription
                  parentTagBg
                  childTitle
                  childSlug
                  childDescription
                  childIcon {
                    url
                  }
                  parentTagSecondaryBg
                  parentSlug
                }

                pages(where: { title: "BugleAuthors" }) {
                  navigationBlock {
                    name
                    slug
                    isDropdown
                  }

                  id
                  title
                  pageBlock {
                    id
                    content {
                      html
                    }
                  }
                  seo {
                    title
                    description
                    keywords
                    image {
                      url
                    }
                  }
                  ctaBoxes {
                    type
                    theme
                    heading {
                      html
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <>
              {data.SuperOps.pages.map(page => {
                const { navigationBlock, pageBlock, seo, ctaBoxes } = page
                return (
                  <>
                    <Frame seo={seo} ogType="blog">
                      <NavigationBlog
                        background="#fff2f1"
                        noWriteForUs
                        links={navigationBlock}
                        libraryLinks={data.SuperOps.navigationContents}
                      />

                      <div
                        style={{
                          display:
                            isAuthors === "Authors" ||
                            isAuthors === "WriteForUs"
                              ? "block"
                              : "none",
                        }}
                        className="blog-authors blog"
                      >
                        <section className="blog-authors-hero">
                          <Container className="altv3">
                            {isAuthors === "Authors" &&
                              parse(pageBlock[0].content.html)}
                            {isAuthors === "WriteForUs" &&
                              parse(pageBlock[1].content.html)}
                            {/* <h1 style={{display: isAuthors === "Authors" ? "block" : "none"}}>Meet some of our amazing contributors at The Bugle</h1>
                  <h1 style={{display: isAuthors === "WriteForUs" ? "block" : "none"}}>Come, be the next big voice of the MSP channel</h1> */}
                          </Container>
                        </section>

                        <section className="blog-authors-content">
                          <Container>
                            <div className="blog-authors-container">
                              <nav className="nav nav-tabs tabs alt">
                                <div
                                  className={`nav-link ${
                                    isAuthors === "Authors" ? "active" : ""
                                  } `}
                                  onClick={() => changeTab("authors")}
                                  // onKeyDown={() => handleTabChange("authors")}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <h5>AUTHORS</h5>
                                </div>
                                <div
                                  className={`nav-link ${
                                    isAuthors === "WriteForUs" ? "active" : ""
                                  } `}
                                  onClick={() => changeTab("nominate")}
                                  // onKeyDown={() => handleTabChange("nominate")}
                                  role="button"
                                  tabIndex={0}
                                >
                                  <h5>WRITE FOR THE BUGLE</h5>
                                </div>
                              </nav>
                            </div>
                            <div className="grid-container content-display">
                              {isAuthors === "Authors" && <AllAuthorsList />}{" "}
                              {isAuthors === "WriteForUs" && (
                                <AuthorNominate
                                  thankYouTitle={parse(
                                    pageBlock[2].content.html
                                  )}
                                  thankYouSubtitle={parse(
                                    pageBlock[3].content.html
                                  )}
                                  title={parse(pageBlock[4].content.html)}
                                  subTitle={parse(pageBlock[5].content.html)}
                                  isSubmitted={isSubmitted}
                                  submitted={() => {
                                    setShowSuggestedStories(true)
                                    setIsSubmitted(true)
                                  }}
                                />
                              )}
                            </div>
                          </Container>
                        </section>
                        {isAuthors === "WriteForUs" ? (
                          <section
                            className="blog-authors-suggested-stories"
                            style={{
                              display: showSuggestedStories ? "block" : "none",
                            }}
                          >
                            <Container>
                              <span className="p16 section-title">
                                <p>MEAN WHILE READ OUR SUGGESTED STORIES</p>
                              </span>
                              <SuggestedStories />
                            </Container>
                          </section>
                        ) : null}
                        <section className="blog-authors-cta">
                          <CTA data={[ctaBoxes[0]]} maxBoxWidth="1104px" />
                        </section>
                      </div>
                    </Frame>
                  </>
                )
              })}
            </>
          )}
        />
      </div>
    </>
  )
}

export default BlogAuthorsList
