import React, {useState} from 'react';
import Fade from "react-reveal/Fade"
import { StaticQuery, graphql } from "gatsby";
import AuthorCard from './authorCard';

let AllAuthors = (props) => {
  const [authorsToBeShown, setAuthorsToBeShown] = useState(15)

  const [authorsCount, setAuthorsCount] = useState(0)

  // const [authorsData, setAuthorsData] = useState([])

  var delayCount = 0;

  // function sortAuthors(authorsArray) {
    
  // }

    return (
        <>
          <StaticQuery
            query={graphql`
              query authorsList {
                SuperOps {
                  authors(where: {team: null}, orderBy: name_ASC) {
                    name
                    title
                    picture {
                      url(
                        transformation: {
                          document: { output: { format: webp } }
                          image: { resize: { width: 68 } }
                        }
                      )
                    }
                    posts {
                      title
                    }
                    slug
                    seo {
                      title
                      description
                      keywords
                      image {
                        url
                      }
                    }
                  }
                }
              }
            `}
            render= {data => (
                <>
                    {setAuthorsCount(data.SuperOps.authors.length)}
                    {/* {setAuthorsData(data.SuperOps.authors)} */}
                    {data.SuperOps.authors.map((author, i) => {
                        const {name, title, picture, slug } = author;
                        if (delayCount >= 15) {
                          delayCount = 0;
                        }
                        ++delayCount;
                        return (
                            <>                              
                              <Fade delay={(delayCount) * 130} duration="500">
                                <div className="grid-lg-3 grid-md-2 grid-sm-1"
                                style = {{display : (i + 1) > authorsToBeShown ? "none" : "block"}} >
                                    <AuthorCard name={name} title={title} picture={picture} slug={slug} />
                                </div>
                              </Fade>
                            </>
                        )}
                    )}
                    {authorsCount <= 15 || authorsToBeShown >= authorsCount ? null : (

                      <div className="view-more-link p14">
                        <p
                          onClick={() => setAuthorsToBeShown(authorsToBeShown + 6)}
                        >
                          {" "}
                          view more{" "}
                          <img
                            src="https://media.graphcms.com/2j9zFnn3SWGuSdxuUPVA"
                            alt="arrow"
                            height="5"
                            width="5"
                          />{" "}
                        </p>
                      </div>
                    )}
                </>
            )}
            />
        </>
    )
}

export default AllAuthors;