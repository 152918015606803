import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import ThankYou from "../../assets/images/popup-anim.gif"
import FormBuilder from "./../common/FormBuilder/Form-Builder"

let AuthorNominate = props => {
  const [submitted, setSubmitted] = useState(false)
  const formdata = [
    {
      name: "firstname",
      type: "text",
      label: "First name",
      parentClassName: "input-50",
      errorMessage: "Please enter a valid first name",
    },
    {
      name: "lastname",
      type: "text",
      label: "Last name",
      parentClassName: "input-50",
      errorMessage: "Please enter a valid last name",
    },
    {
      name: "email",
      type: "email",
      label: "Email address",
      errorMessage: "Please enter a valid email",
    },
    {
      name: "nominee_social_link",
      validateEmpty: "true",
      type: "text",
      label: "Linkedin or website link",
      errorMessage: "Please enter a valid link",
    },
    {
      name: "message",
      validateEmpty: "true",
      type: "textarea",
      label: "About yourself",
      errorMessage: "Please enter a valid message",
    },
  ]

  const handleAfterSubmit = () => {
    setSubmitted(true)
  }

  return (
    <>
      <div id="WriteForUs" className="author-nominate">
        <Fade delay={130} duration="500">
          <div className="row">
            {submitted || props.isSubmitted ? (
              <center>
                <div className="thankyou">
                  <img alt="ThankYou" src={ThankYou} />
                  <h3>{props.thankYouTitle}</h3>
                  <span className="p18">
                    <p>{props.thankYouSubtitle}</p>
                  </span>
                </div>
              </center>
            ) : (
              <>
                <div className="col-lg-6">
                  <div className="left">
                    <h2>{props.title}</h2>
                    <span className="p16">
                      <p>{props.subTitle}</p>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="right">
                    <div className="forms">
                      <FormBuilder
                        formClassName="row"
                        errorClassName="error-message"
                        inputClassName="col-sm"
                        inputErrorClassName="error-active"
                        data={formdata}
                        buttonText="SUBSCRIBE"
                        buttonClassName="primary"
                        endpoint={process.env.HUBSPOT_WRITE_FOR_US_API_ENDPOINT}
                        url={process.env.HUBSPOT_WRITE_FOR_US_API_URL}
                        formName="Authors #Write for us - Superops"
                        afterSubmit={handleAfterSubmit}
                      />

                      <div className="p12 disclaimer">
                        <center>
                          <p>
                            By clicking "SUBMIT", you agree to SuperOps's{" "}
                            <a href="/terms">Terms of use</a> and{" "}
                            <a href="/privacy">Privacy policy</a>.
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </Fade>
      </div>
    </>
  )
}

export default AuthorNominate
